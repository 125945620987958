import { PUBLIC_URL } from "../../shared";
import { CardTypes, LocationCard, LocationCardProps } from "../../shared/components/LocationCard";
import { SpaceY } from "../../shared/components/Utils";
import { BookBanner } from "./components/BookBanner";
import { cityIdState, cityDataState } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const newYorkLocations: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/fake/BIG APPLE PASS.svg`,
    location: "빅애플패스",
    large: true,
    type: CardTypes.ONLY_LOCATION,
    url: "/package-tour/ba-pass",
  },
  {
    image: `${PUBLIC_URL}/fake/전망대.svg`,
    location: "전망대",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/observations",
  },
  {
    image: `${PUBLIC_URL}/fake/뮤지컬.svg`,
    location: "브로드웨이 뮤지컬",
    type: CardTypes.ONLY_LOCATION,
    url: "/musicals_view",
  },
  {
    image: `${PUBLIC_URL}/fake/미술관-박물관.svg`,
    location: "미술관/박물관",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/museum-gallery",
  },
  {
    image: `${PUBLIC_URL}/fake/크루즈.svg`,
    location: "크루즈",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/rides-cruises",
  },
  {
    image: `${PUBLIC_URL}/fake/가이드투어.svg`,
    location: "가이드 투어",
    type: CardTypes.ONLY_LOCATION,
    url: "/guide-tour/manhattan-day",
  },
];

const sanFranciscoLocations: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/images/SF Big Apple Pass.svg`,
    location: "빅애플패스",
    large: true,
    type: CardTypes.ONLY_LOCATION,
    url: "/package-tour/ba-pass",
  },
  {
    image: `${PUBLIC_URL}/images/BigBus.svg`,
    location: "버스투어",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/observations",
  },
  {
    image: `${PUBLIC_URL}/images/Cruise.svg`,
    location: "크루즈",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/observations",
  },
  {
    image: `${PUBLIC_URL}/images/Museum.svg`,
    location: "박물관",
    type: CardTypes.ONLY_LOCATION,
    url: "/guide-tour/sf-museum",
  },
  {
    image: `${PUBLIC_URL}/images/Bike.svg`,
    location: "액티비티",
    type: CardTypes.ONLY_LOCATION,
    url: "/city-attractions/activities",
  },
  {
    image: `${PUBLIC_URL}/images/SimCard.svg`,
    location: "미국유심",
    type: CardTypes.ONLY_LOCATION,
    url: "/sim-card",
  },
];

const newYorkAttractions: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/fake/topoftherock.svg`,
    location: "탑 오브 더 락 전망대",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/368",
  },
  {
    image: `${PUBLIC_URL}/fake/landmark.svg`,
    location: "서클라인 자유의 여신상 랜드마크 크루즈",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/453",
  },
  {
    image: `${PUBLIC_URL}/fake/모마.png`,
    location: "모마 현대 미술관",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/384",
  },
  {
    image: `${PUBLIC_URL}/fake/미술관-박물관.svg`,
    location: "메트로폴리탄 미술관 도슨트 투어",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/414",
  },
];

const sanFranciscoAttractions: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/images/SFCruise.svg`,
    location: "골든 게이트 베이 크루즈",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/487",
  },
  {
    image: `${PUBLIC_URL}/images/SFBigBus.svg`,
    location: "빅버스 1 Day 디스커버 2층버스",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/493",
  },
  {
    image: `${PUBLIC_URL}/images/SFBike.svg`,
    location: "샌프란 올데이 자전거 대여",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/494",
  },
  {
    image: `${PUBLIC_URL}/images/SFCALSci.svg`,
    location: "캘리포니아 과학 아카데미",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/product-detail/490",
  },
];

const newYorkShows: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/fake/Musicals - lion king.svg`,
    location: "라이언킹",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/musicals_view/393",
  },
  {
    image: `${PUBLIC_URL}/fake/Musicals - Aladdin.svg`,
    location: "알라딘",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/musicals_view/382",
  },

  {
    image: `${PUBLIC_URL}/fake/Musicals - Moulin Rouge.svg`,
    location: "물랑루즈",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/musicals_view/386",
  },
  {
    image: `${PUBLIC_URL}/fake/Musicals - Wicked.svg`,
    location: "위키드",
    // type: CardTypes.PLACE_PRICE,
    type: CardTypes.SHOW,
    // oldPrice: 45,
    // newPrice: 30,
    url: "/musicals_view/383",
  },
];

const newYorkTours: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/fake/tour - MOMA.svg`,
    location: "모마 도슨트 투어",
    type: CardTypes.SHOW,
    url: "/product-detail/456",
    // showName: "The Gazillion Bubble Show",
  },
  {
    image: `${PUBLIC_URL}/fake/tour -Brooklyn.svg`,
    location: "브루클린 덤보 야경 워킹 투어",
    type: CardTypes.SHOW,
    url: "/product-detail/484",
    // showName: "The Lion King",
  },
  {
    image: `${PUBLIC_URL}/fake/tour - Niagara.svg`,
    location: "나이아가라 1박2일 자유여행",
    type: CardTypes.SHOW,
    url: "/product-detail/486",
    // showName: "The Lion King",
  },
  {
    image: `${PUBLIC_URL}/fake/tour -UN.svg`,
    location: "UN 한국어 투어",
    type: CardTypes.SHOW,
    url: "/product-detail/485",
    // showName: "The Lion King",
  },
];

const newYorkcommunities: LocationCardProps[] = [
  {
    image: `${PUBLIC_URL}/fake/kakaotalk.svg`,
    location: "카카오톡 채널",
    type: CardTypes.COMMUNITY,
    url: "https://pf.kakao.com/_AAelu",
    // communityText: "Lorem ipsum, or lipsum as it is sometimes",
  },
  {
    image: `${PUBLIC_URL}/fake/blog.svg`,
    location: "네이버 블로그",
    type: CardTypes.COMMUNITY,
    url: "https://blog.naver.com/tamice",
    // communityText: "Lorem ipsum, or lipsum as it is sometimes",
  },
  {
    image: `${PUBLIC_URL}/fake/instagram.svg`,
    location: "인스타그램",
    type: CardTypes.COMMUNITY,
    url: "https://www.instagram.com/with.tamice/",
    // communityText: "Lorem ipsum, or lipsum as it is sometimes",
  },
];


export const MainView = () => {
  // Define Global State Variables
  const [cityId, setCityId] = cityIdState.useState();
  const [cityData, setCityData] = cityDataState.useState();

  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  // Define useEffect Hook
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  if (cityId === 36) {
    // San Francisco
    return (
      <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh] w-full px-20">
        <SpaceY />
        <SpaceY />
        <span className="font-medium font-poppins text-dark">San Francisco</span>
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">Must See in San Francisco</span>
        <SpaceY />
        <SpaceY />
        <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
          {sanFranciscoLocations.map((item) =>
            item.url ? (
              <Link to={item.url}>
                <LocationCard {...item} />
              </Link>
            ) : (
              <LocationCard {...item} />
            )
          )}
        </div>
        <BookBanner />
        {/* ATTRACTIONS SECTION */}
        <SpaceY /> <SpaceY />
        <span className="font-medium font-poppins text-dark">Popular Attractions</span>
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">샌프란 인기 입장지</span>
        <SpaceY /> <SpaceY />
        <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
          {sanFranciscoAttractions.map((item) =>
            item.url ? (
              <Link to={item.url}>
                <LocationCard {...item} />
              </Link>
            ) : (
              <LocationCard {...item} />
            )
          )}
        </div>
        {/* MUSICALS  SECTION */}
        <SpaceY /> <SpaceY />
        {/* <span className="font-medium font-poppins text-dark">Popular shows</span>
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">Musicals/Shows</span>
        <SpaceY /> <SpaceY />
        <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
          {newYorkShows.map((item) => (
            <LocationCard {...item} />
          ))}
        </div> */}
        {/* TOUR SECTION */}
        <SpaceY /> <SpaceY />
        {/* <span className="font-medium font-poppins text-dark">Tour with Us</span>
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">Tour</span>
        <SpaceY /> <SpaceY />
        <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
          {newYorkTours.map((item) =>
            item.url ? (
              <Link to={item.url}>
                <LocationCard {...item} />
              </Link>
            ) : (
              <LocationCard {...item} />
            )
          )}
        </div> */}
        {/* TAMICE COMMUNITY SECTION */}
        <SpaceY /> <SpaceY />
        <span className="font-medium font-poppins text-dark">Let’s Connect</span>
        <SpaceY />
        <span className="text-2xl font-bold font-volkhov text-dark">타미스 커뮤니티</span>
        <SpaceY /> <SpaceY />
        <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
          {newYorkcommunities.map((item) =>
            item.url ? (
              <Link to={item.url}>
                <LocationCard {...item} />
              </Link>
            ) : (
              <LocationCard {...item} />
            )
          )}
        </div>
      </div>
    );
  }

  // New York
  return (
    <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh] w-full px-20">
      <SpaceY />
      <SpaceY />
      <span className="font-medium font-poppins text-dark">New York</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">Must See in New York</span>
      <SpaceY />
      <SpaceY />
      <div className="flex flex-wrap w-full justify-center gap-x-[22px] gap-y-[22px]">
        {newYorkLocations.map((item) =>
          item.url ? (
            <Link to={item.url}>
              <LocationCard {...item} />
            </Link>
          ) : (
            <LocationCard {...item} />
          )
        )}
      </div>
      <BookBanner />
      {/* ATTRACTIONS SECTION */}
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">Popular Attractions</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">뉴욕 인기 입장지</span>
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap  justify-center w-full gap-x-[22px] gap-y-[22px]">
        {newYorkAttractions.map((item) =>
          item.url ? (
            <Link to={item.url}>
              <LocationCard {...item} />
            </Link>
          ) : (
            <LocationCard {...item} />
          )
        )}
      </div>
      {/* MUSICALS  SECTION */}
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">Broadway Musical</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">브로드웨이 뮤지컬</span>
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap  justify-center w-full gap-x-[22px] gap-y-[22px]">
        {newYorkShows.map((item) =>
          item.url ? (
            <Link to={item.url}>
              <LocationCard {...item} />
            </Link>
          ) : (
            <LocationCard {...item} />
          )
        )}
      </div>
      {/* TOUR SECTION */}
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">Guide Tour</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">타미스와 함께하는 여행</span>
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap  justify-center w-full gap-x-[22px] gap-y-[22px]">
        {newYorkTours.map((item) =>
          item.url ? (
            <Link to={item.url}>
              <LocationCard {...item} />
            </Link>
          ) : (
            <LocationCard {...item} />
          )
        )}
      </div>
      {/* TAMICE COMMUNITY SECTION */}
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">Let’s Connect</span>
      <SpaceY />
      <span className="text-2xl font-bold font-volkhov text-dark">타미스 커뮤니티</span>
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap  justify-center w-full gap-x-[22px] gap-y-[22px]">
        {newYorkcommunities.map((item) =>
          item.url ? (
            <Link to={item.url} target="_blank">
              <LocationCard {...item} />
            </Link>
          ) : (
            <LocationCard {...item} />
          )
        )}
      </div>
    </div>
  );
};
