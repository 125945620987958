import { staticFiles } from "..";

export enum CardTypes {
  CITY_LOCATION,
  ONLY_LOCATION,
  PLACE_PRICE,
  SHOW,
  COMMUNITY,
}

export type LocationCardProps = {
  type: CardTypes;
  image: string;
  city?: string;
  location: string;
  large?: boolean;
  oldPrice?: number;
  newPrice?: number;
  showName?: string;
  communityText?: string;
  url?: string;
  cityId?: string | number;
  handleNavigate?: () => void;
};

export const LocationCard: React.FC<LocationCardProps> = ({
  city,
  image,
  location,
  large,
  type,
  oldPrice,
  newPrice,
  showName,
  communityText,
  handleNavigate,
}) => {
  const width = large
    ? "grow max-w-[260px] md:max-w-[700px] min-w-[250px] md:min-w-[825px] cursor-pointer "
    : "grow max-w-[260px] min-w-[250px] cursor-pointer ";

  if (type === CardTypes.SHOW)
    return (
      <div
        className={`rounded-xl flex flex-col h-[350px] ${width} drop-shadow-xl bg-white overflow-hidden`}
      >
        <img className="min-h-[250px] object-cover" src={image} />
        <div className="flex items-center h-full px-5 justify-center">
          {/* <img width={17} src={staticFiles.icons.location} /> */}
          <span className=" font-poppins text-darkGray">{location}</span>
        </div>
        <span className="flex justify-end px-5 pb-3 font-poppins text-darkGray gap-x-3">
          {showName}
        </span>
      </div>
    );

  if (type === CardTypes.PLACE_PRICE)
    return (
      <div
        className={`rounded-xl flex flex-col h-[350px] ${width} drop-shadow-xl bg-white overflow-hidden`}
      >
        <img className="min-h-[250px] object-cover" src={image} />
        <div className="flex items-center h-full px-5 justify-center">
          {/* <img width={17} src={staticFiles.icons.location} /> */}
          <span className=" font-poppins text-darkGray">{location}</span>
        </div>
        <div className="flex justify-end px-5 pb-3 font-poppins gap-x-3">
          <span className="text-[#999999] line-through">${oldPrice || 0}</span>
          <span className="text-blue">${newPrice || 0}</span>
        </div>
      </div>
    );

  if (type === CardTypes.CITY_LOCATION || type == CardTypes.ONLY_LOCATION)
    return (
      <div
        className={`rounded-xl flex flex-col h-[350px] ${width} drop-shadow-xl bg-white overflow-hidden`}
        onClick={handleNavigate}
      >
         <img className="min-h-[250px] object-cover" src={image} />
      
        <div className="flex items-center h-full justify-center px-5">
          {/* <img width={17} src={staticFiles.icons.location} /> */}
          {city && (
          <span className=" font-poppins text-darkGray">{city}</span>
        )}
          <span className=" font-poppins text-darkGray">{location}</span>
        </div>
      </div>
    );

  if (type === CardTypes.COMMUNITY)
    return (
      <div
        className={`rounded-xl flex flex-col h-[350px] ${width} drop-shadow-xl bg-white overflow-hidden`}
      >
        <img className="min-h-[250px] object-cover" src={image} />
        <div className="flex items-center h-full px-5 justify-center">
          {/* <img width={17} src={staticFiles.icons.location} /> */}
          <span className=" font-poppins text-darkGray">{location}</span>
        </div>
        <span className="px-5 pb-2 font-poppins text-darkGray">
          {communityText}
        </span>
      </div>
    );

  return null;
};
