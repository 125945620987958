import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { cityIdState, cityDataState } from "../../App";
import { PUBLIC_URL } from "../../shared";
import { CardTypes, LocationCard, LocationCardProps } from "../../shared/components/LocationCard";
import { SpaceY } from "../../shared/components/Utils";

// Define fakeLocations
const fakeLocations: LocationCardProps[] = [
  {
    city: "뉴욕",
    image: `${PUBLIC_URL}/fake/MAIN UNDER_NY.svg`,
    location: "",
    type: CardTypes.CITY_LOCATION,
    cityId: 1,
  },
  {
    city: "샌프란시스코",
    image: `${PUBLIC_URL}/fake/MAIN UNDER_SF.svg`,
    location: "",
    type: CardTypes.CITY_LOCATION,
    cityId: 36,
  },
];


export const LandingView = () => {
  // Define Global State variables
  const [cityId, setCityId] = cityIdState.useState();

  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  // Function to handle navigate action
  const handleNavigate = (cityId: any, cityName: any) => {
    // set city data based selected item
    setCityId(cityId);
    localStorage.setItem("cityId", String(cityId));
    localStorage.setItem("cityName", cityName);

    // navigate to main page
    navigate("/main");
  };

  // Define useEffect Hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);


  return (
    <div className="min-h-[600px] flex flex-col items-center pt-[5vh] pb-[20vh] w-full">
      <SpaceY /> <SpaceY />
      <span className="font-medium font-poppins text-dark">미국으로 떠나볼까요?</span>
      <SpaceY />
      {/* <span className="text-2xl font-bold font-volkhov text-dark">Destinations</span> */}
      <SpaceY /> <SpaceY />
      <div className="flex flex-wrap justify-around w-full gap-y-10 gap-x-64 px-[5rem]">
        {fakeLocations.map((item) => (
          <LocationCard {...item} handleNavigate={() => handleNavigate(item.cityId, item.city)} />
        ))}
      </div>
    </div>
  );
};
