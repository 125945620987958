import { useLocation } from "react-router-dom"; // Adjusted import for correctness
import { useEffect, useState, useMemo } from "react";
import { cityIdState } from "../../App";
import { nyConfig } from "./Banner-city/nyBanner"; // Adjust path as necessary
import { sfConfig } from "./Banner-city/sfBanner"; // Adjust path as necessary
import { BannerConfig, CityConfig } from "./Banner-city/cityTypes"; // Adjust the import path as necessary
import { staticFiles } from "../index";

export const Cover = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>("");
  const [cityId] = cityIdState.useState();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const getBannerImages = useMemo<BannerConfig>(() => {
    let config: BannerConfig | {} = {};

    if (cityId === 1) {
      config = nyConfig[currentPath as keyof CityConfig] || {};
    } else if (cityId === 36) {
      config = sfConfig[currentPath as keyof CityConfig] || {};
    }

    const defaultConfig: BannerConfig = {
      backgroundImage: staticFiles.images.toprock_cover_bg,
      mobileBanner: staticFiles.images.toprock_mobile_bannerFont,
      pcBanner: staticFiles.images.toprock_pc_bannerFont,
    };

    return { ...defaultConfig, ...config };
  }, [currentPath, cityId]);

  return (
    <div className="w-full">
      <div className="relative flex justify-center w-full h-1/2">
        <img
          className="object-cover object-center w-full h-[400px]"
          src={getBannerImages.backgroundImage}
          alt="Background"
        />
        <img
          className="absolute lg:hidden top-[22vh] object-cover object-center h-[90px]"
          src={getBannerImages.mobileBanner}
          alt="Mobile Banner"
        />
        <img
          className="absolute hidden object-cover object-center transform -translate-x-1/2 -translate-y-1/2 lg:block top-1/2 left-1/2"
          src={getBannerImages.pcBanner}
          alt="PC Banner"
        />
      </div>
    </div>
  );
};
