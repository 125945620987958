import { staticFiles } from "../../../shared";

export const BookBanner = () => {
  return (
    <div className="sm:w-full flex flex-col my-20 min-w-[250px]">
      <div className="w-full font-poppins text-darkGray">쉽고 빠르게 입장지를 예약하세요!</div>
      <div className="flex flex-col gap-8 md:flex-row">
        <div className="flex flex-col w-full md:w-1/2">
          <span className="text-2xl font-bold font-volkhov text-dark">
            빅애플패스 구매 / 이용 방법
          </span>
          {activities.map((item) => (
            <Activity {...item} />
          ))}
        </div>
        <div className="flex items-center justify-end w-full md:w-1/2">
          <div
            className="bg-white rounded-xl flex 
           w-full h-[400px] drop-shadow-xl overflow-hidden justify-center items-center"
          >
            <img
            
              src={staticFiles.images.main_page_slide}
              className="object-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const activities: ActivityProps[] = [
  {
    title: "원하시는 입장지를 패스로 묶어서 저렴하게!",
    text: "많이 담을수록 저렴해지는 빅애플패스로 알찬 미국여행을 계획해 보세요",
    icon: staticFiles.icons.activity_1,
  },
  {
    title: "티켓 수령은 마이페이지에서 편리하게!",
    text: "구매하신 입장지 티켓은 마이페이지에서 원하실 때 다운로드 받으실 수 있습니다.",
    icon: staticFiles.icons.activity_2,
  },
  {
    title: "고객의 마음에 쏙 드는 환불과 입장지 변경!",
    text: "예약 전, 다운로드 전에는 원하시는 대로 입장지 추가, 환불, 변경이 가능합니다.",
    icon: staticFiles.icons.activity_3,
  },
];

type ActivityProps = { title: string; text: string; icon: string };

const Activity: React.FC<ActivityProps> = ({ icon, text, title }) => (
  <div className="flex mt-8">
    <img src={icon} />
    <div className="flex flex-col ml-4 gap-y-1">
      <span className="text-sm font-medium font-poppins">{title}</span>
      <span className="text-sm font-poppins">{text}</span>
    </div>
  </div>
);
