import { useState, useEffect, useMemo, useCallback, memo, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import download from "downloadjs";
import { toast } from "react-toastify";

import { staticFiles } from "../../shared";
import { useGetReservations, updateScheduleOptions } from "../../shared/hooks";
import { API } from "../../shared";
import {
  CartItem,
  cartState,
  reservationsState,
  reservationsParsedState,
  cityDataState,
  cityIdState,
} from "../../App";
import { Modal } from "./Modal";
import { ModalSpinner } from "./ModalSpinner";
import "./styles.css";
import { formatDateTime } from "../../shared/components/Utils";

// CSS property variable
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

// Convert 2023-08-30 13:00:00 to ISO8601 format
const convertToISO8601 = (dateString: string) => {
  // Parse date and time from the string
  const [date, time] = dateString.split(" ");
  const [year, month, day] = date.split("-");
  const [hour, minute, second] = time.split(":");

  let dateInUTC = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // Month is 0-based in JavaScript
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    )
  );

  let isoDate = dateInUTC.toISOString().split(".000Z")[0];
  return isoDate;
};

// Variable to store subpath abbreviation
const subPathWithAbbreviation = [
  { abb: "뉴욕", name: "뉴욕" },
  { abb: "샌프란", name: "샌프란시스코" },
  { abb: "LA", name: "Los Angeles" },
];

const subPathFromSubCategoryName = [
  { name: "뉴욕빅애플패스", subPath: "ba-pass" },
  { name: "뉴욕시티패스", subPath: "city-pass" },
  { name: "뉴욕익스플로러패스", subPath: "explore-pass" },
  { name: "샌프란 빅애플패스", subPath: "ba-pass" },
  { name: "샌프란 시티패스", subPath: "city-pass" },
  { name: "샌프란 익스플로러패스", subPath: "explore-pass" },

  { name: "Observation(Scenics)", subPath: "observations" },
  { name: "Museum/Gallery", subPath: "museum-gallery" },
  { name: "Rides/Cruises", subPath: "rides-cruises" },
  { name: "Activities", subPath: "activities" },

  { name: "Manhattan Day Tour", subPath: "manhattan-day" },
  { name: "Manhattan Night Tour", subPath: "manhattan-night" },
  { name: "Doson Tour", subPath: "doson-tour" },
  { name: "UN Tour", subPath: "un-tour" },
  { name: "My Page", subPath: "my-page" },
  { name: "Account", subPath: "account" },
];

// Component with memo for single tickect reservation items
const TicketViewMemo = memo(
  ({
    isLoading,
    orderNumber,
    item,
    reservation,
    showModal,
    handleShow,
    modalForm,
    handleChange,
    handleSubmit,
    setIsDownLoadTicket,
    isSubmitted,
    hasSubcategory,
  }: any) => {
    // Define global state variables
    const [cart, setCart] = cartState.useState();
    const [cityData, setCityData] = cityDataState.useState();
    const [cityId, setCityId] = cityIdState.useState();

    // Define component state variables
    const [downloading, setDownloading] = useState(false);
    const [isIssueInInventory, setIsIssueInInventory] = useState(false);

    // Create navigate instance
    const navigate = useNavigate();

    // Get items from reservation props
    const items = reservation.items;

    // Function to use when clicking download button
    const DownloadTicket = (url: string) => {
      Swal.fire({
        icon: "question",
        title: "다운로드 확인",
        html: `티켓받기를 하시겠습니까?<br>티켓받기를 하시면 교환 / 변경 / 취소 / 환불이 불가능합니다.`,
        showCancelButton: true,
        confirmButtonText: "확인",
        cancelButtonText: "아니오",
      }).then((result) => {
        // Download only when user confirms to download
        if (result.isConfirmed) {
          // Parent component setIsDownLoadTicket for refetching data
          setIsDownLoadTicket(true);
          setDownloading(true);
          // Make a POST request to get the file data
          axios({
            url,
            method: "POST",
            responseType: "blob", // Specify the response type as blob
          })
            .then((response) => {
              const content = response.headers["content-type"];
              // Get the current date and time
              // const currentDate = new Date();
              // const formattedDate = currentDate.toISOString().replace(/[^\d]/g, '').slice(0, 14);

              // Get the current date and time
              const currentDate = new Date();

              // Extract date components
              const year = currentDate.getFullYear();
              const month = String(currentDate.getMonth() + 1).padStart(2, "0");
              const day = String(currentDate.getDate()).padStart(2, "0");

              // Extract time components
              const hours = String(currentDate.getHours()).padStart(2, "0");
              const minutes = String(currentDate.getMinutes()).padStart(2, "0");
              const seconds = String(currentDate.getSeconds()).padStart(2, "0");

              // Create the formatted date and time string
              const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;

              // Define the prefix and file extension
              const fileNamePrefix = "ticket";
              const fileExtension = "pdf";

              // Concatenate the prefix, current date/time, and file extension
              const fileName = `${fileNamePrefix}_${formattedDateTime}`;

              download(response.data, fileName, content);
              toast.success("Downloaded successfully!");
            })
            .catch((error) => {
              if (error?.response?.status === 400 || error?.response?.status === 422) {
                // toast.error(
                //   "There was a problem in the inventory. Please contact the Admin for the error for code: 4848!"
                // );
                setIsIssueInInventory(true);
              } else {
                toast.error("Something is wrong on the Server");
              }
            })
            .finally(() => {
              setDownloading(false);
              setIsDownLoadTicket(false);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Do nothing if cancel
          return;
        }
      });
    };

    useEffect(() => {
      return () => {
        Swal.close();
      };
    }, []);

    return (
      <>
        {isIssueInInventory && <ConfirmBox onConfirm={() => setIsIssueInInventory(false)} />}
        {downloading && (
          <ModalSpinner
            onClose={() => {
              // close actions here
            }}
          >
            <div style={{ width: "100px", margin: "auto", display: "block" }}>
              <img
                alt=""
                src={staticFiles.icons.schedule_optionconfirm}
                style={{ position: "absolute", marginLeft: "10px", top: "55px" }}
              />
              <ClipLoader color="#52bfd9" size={120} />
            </div>
          </ModalSpinner>
        )}
        {item?.tickets
          ?.sort((a: any, b: any) => a.subitem_id - b.subitem_id)
          .map((ticket: any, i: number) => {
            const individualTicketBorderStyles =
              !hasSubcategory &&
              "border-dashed border-2 border-gray my-5 text-xs sm:text-sm sm:font-poppins";
            let sameTicketNum = 0;
            items.forEach((item: any) => {
              item.tickets.forEach((singleTicket: any) => {
                if (
                  ticket.ticket_id === singleTicket.ticket_id &&
                  singleTicket.options_schedules.length === 0
                )
                  sameTicketNum++;
              });
            });
            return (
              <div
                key={i}
                className={`grid grid-cols-4 sm:grid-cols-7 text-center text-xs text-left sm:text-sm items-center p-1 text-[#5D5D5F] ${individualTicketBorderStyles}`}
              >
                <div className="flex flex-col gap-2 py-2 justify-center items-start col-span-1 sm:col-span-1 text-left text-[#5D5D5F]">
                  <div className=" sm:font-normal text-blue">
                    <button
                      className="text-left underline cursor-pointer"
                      onClick={() => {
                        const currentCityId = localStorage.getItem("cityId");

                        if (ticket?.ticket_city_id === "1") {
                          // NY
                          setCityId(1);

                          localStorage.setItem("cityId", "1");
                          localStorage.setItem("cityName", "뉴욕");

                          if (currentCityId != "1") {
                            window.location.reload();
                          }
                        } else {
                          setCityId(36);
                          localStorage.setItem("cityId", "36");
                          localStorage.setItem("cityName", "샌프란시스코");

                          if (currentCityId != "36") {
                            window.location.reload();
                          }
                        }

                        if (ticket?.ticket_type === "Musicals & Shows") {
                          window.open(`/musicals_view/${ticket.ticket_id}`, "_blank");
                        } else {
                          if (ticket?.ticket_id == "159" || String(ticket?.ticket_id) == "159") {
                            // NY city pass
                            setCityId(1);

                            localStorage.setItem("cityId", "1");
                            localStorage.setItem("cityName", "뉴욕");

                            if (currentCityId != "1") {
                              window.location.reload();
                            }
                            window.open(`/package-tour/city-pass`, "_blank");
                          } else if (
                            ticket?.ticket_id == "160" ||
                            String(ticket?.ticket_id) == "160"
                          ) {
                            // NY explore
                            setCityId(1);
                            localStorage.setItem("cityId", "1");
                            localStorage.setItem("cityName", "뉴욕");

                            if (currentCityId != "1") {
                              window.location.reload();
                            }
                            window.open(`/package-tour/explore-pass`, "_blank");
                          } else if (
                            ticket?.ticket_id == "214" ||
                            String(ticket?.ticket_id) == "214"
                          ) {
                            // SF city pass
                            setCityId(36);
                            localStorage.setItem("cityId", "36");
                            localStorage.setItem("cityName", "샌프란시스코");

                            if (currentCityId != "36") {
                              window.location.reload();
                            }
                            window.open(`/package-tour/city-pass`, "_blank");
                          } else if (
                            ticket?.ticket_id == "215" ||
                            String(ticket?.ticket_id) == "215"
                          ) {
                            // SF explore
                            setCityId(36);
                            localStorage.setItem("cityId", "36");
                            localStorage.setItem("cityName", "샌프란시스코");

                            if (currentCityId != "36") {
                              window.location.reload();
                            }
                            window.open(`/package-tour/explore-pass`, "_blank");
                          } else {
                            window.open(`/product-detail/${ticket.ticket_id}`, "_blank");
                          }
                        }
                      }}
                    >
                      {!item?.hasSubcategory && item?.price_list_id
                        ? item?.product_name
                        : ticket?.ticket_title_kr}
                    </button>

                    {ticket?.ticket_type == "Musicals & Shows" && (
                      <div className="text-xs text-green-600">{ticket?.seating_info}</div>
                    )}
                  </div>
                  <div className="flex flex-wrap items-center justify-start sm:justify-center sm:hidden">
                    {(ticket?.ticket_type === "Guide Tour" ||
                      ticket?.ticket_type === "City Explore Pass" ||
                      ticket?.ticket_type === "Musicals & Shows") && (
                      <span className="mr-2 text-green-600">
                        {ticket?.ticket_sent_status == "Office Pickup" ||
                        ticket?.ticket_sent_status == "Sent"
                          ? ""
                          : ticket?.ticket_sent_status}
                      </span>
                    )}
                    {(ticket?.ticket_type === "SIM card" ||
                      ticket?.ticket_type === "Hard copy") && (
                      <span className="mr-2 text-green-600">
                        {ticket?.ticket_sent_status == "Office Pickup" ||
                        ticket?.ticket_sent_status == "Sent"
                          ? ""
                          : ticket?.ticket_sent_status}
                      </span>
                      // <span className="mr-2 text-blue">Office Pickup</span>
                    )}
                    {/* // <span className="mr-2 text-blue"> Download</span> */}
                    {ticket?.ticket_type === "Bar/QR code" &&
                      ticket?.ticket_sent_status === "환불완료" && <p>환불완료</p>}
                    {ticket?.ticket_type === "Bar/QR code" &&
                      ticket?.ticket_sent_status !== "환불완료" && (
                        <a
                          className="underline cursor-pointer text-sky-700"
                          onClick={() =>
                            DownloadTicket(
                              `${API}/reservations/${item.reservation_id}/reservation-subitems/${ticket?.subitem_id}/email`
                            )
                          }
                        >
                          티켓받기
                        </a>
                      )}

                    <span className="flex justify-start text-[#0D9323] sm:hidden">
                      <ScheduledDateComponent
                        isLoading={isLoading}
                        input={ticket?.rq_schedule_datetime}
                        orderNumber={orderNumber}
                        isMultipe={
                          sameTicketNum === 1 || ticket?.options_schedules.length !== 0
                            ? false
                            : true
                        }
                        subitem_id={ticket?.subitem_id}
                        options_schedules={ticket?.options_schedules}
                        item_quantity={item?.quantity}
                        showModalProp={showModal}
                        handleShow={handleShow}
                        modalForm={modalForm}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitted={isSubmitted}
                        ticketName={ticket?.ticket_title_kr}
                        ticketType={ticket?.ticket_type}
                        ticketSentStatus={ticket?.ticket_sent_status}
                      />
                    </span>
                  </div>
                  <div>{/* <span className="block sm:hidden"> Scheduled Date:</span> */}</div>
                </div>

                <div className="flex items-center justify-center py-2 ">
                  {!hasSubcategory &&
                    (ticket?.ticket_type === "SIM card" || ticket?.ticket_type == "Musicals & Shows"
                      ? ""
                      : item.adult_child_type)}
                </div>
                <div className="flex items-center justify-center hidden py-2 font-poppins sm:block">
                  <ScheduledDateComponent
                    isLoading={isLoading}
                    input={ticket?.rq_schedule_datetime}
                    orderNumber={orderNumber}
                    isMultipe={
                      sameTicketNum === 1 || ticket?.options_schedules.length !== 0 ? false : true
                    }
                    subitem_id={ticket?.subitem_id}
                    options_schedules={ticket?.options_schedules}
                    item_quantity={item?.quantity}
                    showModalProp={showModal}
                    handleShow={handleShow}
                    modalForm={modalForm}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitted={isSubmitted}
                    ticketName={ticket?.ticket_title_kr}
                    ticketType={ticket?.ticket_type}
                    ticketSentStatus={ticket?.ticket_sent_status}
                  />
                </div>
                <div className="flex items-center justify-center py-2">
                  {!hasSubcategory && item.quantity}
                </div>
                <div className="flex items-center justify-center hidden py-2 sm:block">
                  {(ticket?.ticket_type === "Guide Tour" ||
                    ticket?.ticket_type === "City Explore Pass" ||
                    ticket?.ticket_type === "Musicals & Shows") && (
                    <div className="flex items-center justify-center py-2">
                      {" "}
                      {ticket?.ticket_sent_status == "Office Pickup" ||
                      ticket?.ticket_sent_status == "Sent"
                        ? ""
                        : ticket?.ticket_sent_status}{" "}
                    </div>
                  )}
                  {ticket?.ticket_type === "Regular" && (
                    <div className="flex items-center justify-center py-2">
                      {" "}
                      {ticket?.ticket_sent_status == "Office Pickup" ||
                      ticket?.ticket_sent_status == "Sent"
                        ? ""
                        : ticket?.ticket_sent_status}{" "}
                    </div>
                  )}
                  {(ticket?.ticket_type === "SIM card" || ticket?.ticket_type === "Hard copy") &&
                    ticket?.ticket_id !== "159" &&
                    ticket?.ticket_id !== "160" &&
                    ticket?.ticket_id !== "214" &&
                    ticket?.ticket_id !== "215" && (
                      <div className="flex items-center justify-center py-2">
                        {ticket?.ticket_sent_status == "Office Pickup" ||
                        ticket?.ticket_sent_status == "Sent"
                          ? ""
                          : ticket?.ticket_sent_status}
                      </div>
                    )}
                  {ticket?.ticket_type === "Bar/QR code" && (
                    <div className="flex items-center justify-center py-2">
                      {ticket?.ticket_sent_status == "환불완료" ? (
                        <p>환불완료</p>
                      ) : (
                        <a
                          className="underline cursor-pointer text-sky-700"
                          onClick={() =>
                            DownloadTicket(
                              `${API}/reservations/${item.reservation_id}/reservation-subitems/${ticket?.subitem_id}/email`
                            )
                          }
                        >
                          티켓받기
                        </a>
                      )}
                    </div>
                  )}
                </div>
                {/* <div className="flex items-center justify-center hidden py-2 md:block">
                  {!hasSubcategory ? item.item_id : ticket.subitem_id}
                </div> */}
                {!hasSubcategory &&
                  ((ticket?.ticket_type === "Bar/QR code" &&
                    !ticket?.pdf_path &&
                    ticket?.refund_status !== "환불완료" &&
                    ticket?.ticket_sent_status !== "환불완료") ||
                    (ticket?.ticket_sent_status == "Office Pickup" &&
                      (ticket?.ticket_type === "Hard copy" ||
                        ticket?.ticket_type === "SIM card")) ||
                    (ticket?.ticket_type === "Regular" &&
                      ticket?.ticket_sent_status !== "Sent" &&
                      ticket?.ticket_sent_status !== "pending" &&
                      ticket?.options_schedules.length !== 3 &&
                      ticket?.refund_status !== "환불완료" &&
                      ticket?.refund_status !== "In Progress")) &&
                  !(ticket?.ticket_id == "159" || String(ticket?.ticket_id) == "159") &&
                  !(ticket?.ticket_id == "160" || String(ticket?.ticket_id) == "160") &&
                  !(ticket?.ticket_id == "214" || String(ticket?.ticket_id) == "214") &&
                  !(ticket?.ticket_id == "215" || String(ticket?.ticket_id) == "215") &&
                  ticket?.type !== "City Explore Pass" && (
                    <button
                      className="py-2 underline text-sky-700"
                      onClick={() => {
                        if (checkItemsInShoppingCart(cart)) {
                          Swal.fire({
                            icon: "warning",
                            //title: "Oops...",
                            title: "장바구니에 담을 수 없습니다.",
                            text: "현재 진행 중인 내역이 있습니다. 장바구니를 확인해 주세요.",
                            //text: "You already have items in the shopping cart, Please process the existing items first in order to proceed the Booking Edit",
                            confirmButtonText: "OK",
                          });
                        } else {
                          if (item?.quantity > 1) {
                            Swal.fire({
                              title: "수정하기",
                              //text: `The entire quantity ${item?.quantity} will be reflected when editing. Do you like to proceed?`,
                              html: `구매하신 수량 ${item?.quantity}개에 대해 수정이 진행됩니다. <br>계속하시겠습니까?`,
                              showCancelButton: true,
                              confirmButtonText: "네",
                              cancelButtonText: "아니오",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                localStorage.setItem("Edit_Item", JSON.stringify(item));

                                const currentCityId = localStorage.getItem("cityId");

                                if (ticket?.ticket_city_id === "1") {
                                  // NY
                                  setCityId(1);

                                  localStorage.setItem("cityId", "1");
                                  localStorage.setItem("cityName", "뉴욕");

                                  if (currentCityId != "1") {
                                    window.location.reload();
                                  }
                                } else {
                                  setCityId(36);
                                  localStorage.setItem("cityId", "36");
                                  localStorage.setItem("cityName", "샌프란시스코");

                                  if (currentCityId != "36") {
                                    window.location.reload();
                                  }
                                }

                                window.open(
                                  `/product-detail/${ticket.ticket_id}?edit=true&type=booking`,
                                  "_blank"
                                );
                              } else if (result.dismiss === Swal.DismissReason.cancel) {
                                return;
                              }
                            });
                          } else {
                            localStorage.setItem("Edit_Item", JSON.stringify(item));

                            const currentCityId = localStorage.getItem("cityId");

                            if (ticket?.ticket_city_id === "1") {
                              // NY
                              setCityId(1);

                              localStorage.setItem("cityId", "1");
                              localStorage.setItem("cityName", "뉴욕");

                              if (currentCityId != "1") {
                                window.location.reload();
                              }
                            } else {
                              setCityId(36);
                              localStorage.setItem("cityId", "36");
                              localStorage.setItem("cityName", "샌프란시스코");

                              if (currentCityId != "36") {
                                window.location.reload();
                              }
                            }

                            window.open(
                              `/product-detail/${ticket.ticket_id}?edit=true&type=booking`,
                              "_blank"
                            );
                          }
                        }
                      }}
                    >
                      수정하기
                    </button>
                  )}
              </div>
            );
          })}
      </>
    );
  }
);

// Interface for confirmbox definition
interface ConfirmBoxProps {
  onConfirm: () => void;
}

// Confirmbox component
const ConfirmBox: React.FC<ConfirmBoxProps> = ({ onConfirm }) => {
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-opacity-50">
      <div className="flex flex-col justify-center bg-white gap-8 p-8 rounded-[10px] w-1/3 shadow-2xl w-[350px] h-[400px] z-50 h-auto">
        <div className="flex flex-col items-center justify-center block w-full">
          <img src={staticFiles.images.logo} width="100" alt="Tamice logo" />
        </div>
        <div className="w-full text-[#5D5D5F] text-center text-lg mb-8">
          <h1>티켓 준비중입니다!</h1>
        </div>
        <div className="w-full h-auto text-[#5D5D5F] text-start">
          <p className="text-[#009eef]">
            <a href="https://pf.kakao.com/_AAelu" target="_blank">
              타미스 카카오톡 채널
            </a>
          </p>
          <br />
          <p>
            {" "}
            이메일 <span className="text-[#009eef]">(service@tamice.com)</span>{" "}
          </p>
          <br />
          <p> 전화 (646-684-4848) 로 </p>
          <br />
          <p> 문의해 주세요. </p>
        </div>

        {/* <div className="w-full text-[#5D5D5F] text-center">Thank you!</div> */}
        <div className="flex justify-center">
          <button
            className="px-4 py-2 mr-2 text-white bg-gray-300 rounded bg-blue"
            onClick={onConfirm}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

// Component to display schedule related data
const ScheduledDateComponent = ({
  isLoading,
  input,
  orderNumber,
  isMultipe,
  subitem_id,
  options_schedules,
  item_quantity,
  showModalProp,
  handleShow,
  modalForm,
  handleChange,
  handleSubmit,
  isSubmitted,
  ticketName,
  ticketType,
  ticketSentStatus,
}: {
  isLoading: boolean;
  input: any;
  orderNumber: string;
  isMultipe: boolean;
  subitem_id: number;
  options_schedules: any;
  item_quantity: number;
  showModalProp: any;
  handleShow: any;
  modalForm: any;
  handleChange: any;
  handleSubmit: any;
  isSubmitted: any;
  ticketName: string;
  ticketType: string;
  ticketSentStatus: string;
}) => {
  const [options, setOptions] = useState<any>([]);
  const [allowMultipe, setAllowMultipe] = useState(false);
  //setcurrentticket_id
  // const [showThankModal, setShowThankModal] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [topping, setTopping] = useState("");
  const [firstValueToRender, setFirstValueToRender] = useState("");
  const [secondValueToRender, setSecondValueToRender] = useState("");
  const [thirdValueToRender, setThirdValueToRender] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [thirdDate, setThirdDate] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);
  let schedule_options = modalForm[String(subitem_id)];

  // if (options_schedules?.length === 3 && !input) {
  //   firstValueToRender = options_schedules[0]?.datetime;
  //   secondValueToRender = options_schedules[1]?.datetime;
  //   thirdValueToRender = options_schedules[2]?.datetime;

  //   isDisabled = true;
  // }
  useEffect(() => {
    schedule_options = modalForm[String(subitem_id)];
    let Disabled = schedule_options?.reduce((acc: any, item: any) => {
      return acc && typeof item === "string";
    }, true);
    if (input) {
      setIsDisabled(Disabled);
      setFirstValueToRender(options_schedules && options_schedules[0]?.datetime);
      setSecondValueToRender(options_schedules && options_schedules[1]?.datetime);
      setThirdValueToRender(options_schedules && options_schedules[2]?.datetime);
    } else {
      setIsDisabled(Disabled);
      setFirstValueToRender(schedule_options && schedule_options[0]);
      setSecondValueToRender(schedule_options && schedule_options[1]);
      setThirdValueToRender(schedule_options && schedule_options[2]);
    }
  }, []);

  useEffect(() => {
    if (firstDate !== "" && secondDate !== "" && thirdDate !== "") {
      setIsSubmitShow(true);
    }
  }, [firstDate, secondDate, thirdDate]);

  useEffect(() => {
    if (isSubmitted && submitDone) {
      schedule_options = modalForm[String(subitem_id)];
      if (schedule_options?.length === 3 && !input) {
        setFirstValueToRender(schedule_options[0]?.datetime);
        setSecondValueToRender(schedule_options[1]?.datetime);
        setThirdValueToRender(schedule_options[2]?.datetime);

        setIsDisabled(true);
      }
      setOptions(schedule_options);
    } else {
      schedule_options = modalForm[String(subitem_id)];

      if (!schedule_options) {
        if (options_schedules?.length === 3 && !input) {
          setFirstValueToRender(options_schedules[0]?.datetime);
          setSecondValueToRender(options_schedules[1]?.datetime);
          setThirdValueToRender(options_schedules[2]?.datetime);

          setIsDisabled(true);
        }
        setOptions(options_schedules);
      } else {
        if (schedule_options?.length === 3 && !input) {
          setFirstValueToRender(schedule_options[0]);
          setSecondValueToRender(schedule_options[1]);
          setThirdValueToRender(schedule_options[2]);

          setIsDisabled(true);
        }
        setOptions(schedule_options);
      }
    }

    if (isSubmitted) {
      setSubmitDone(false);
    }
  }, [isSubmitted, options_schedules, modalForm]);

  const onOptionChange = (e: any) => {
    if (e.target.value === "Yes") {
      setAllowMultipe(true);
    } else {
      setAllowMultipe(false);
    }
    setTopping(e.target.value);
  };

  if (!isNaN(Date.parse(input)) && ticketType !== "Regular") {
    return <div>{formatDateTime(input)}</div>;
  } else if (ticketType === "Guide Tour") {
    return <div>대기 중</div>;
  } else if (ticketType === "Regular") {
    let rq_schedule_datetime;
    if (input) {
      const dateTime = new Date(input);
      let formattedDate = dateTime.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const parts = formattedDate.split("/");
      formattedDate = `${parts[2].slice(0, 4)}-${parts[0]}-${parts[1]}`;

      const formattedTime = dateTime.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });

      rq_schedule_datetime = `${formattedDate} ${formattedTime}`;
    }

    return (
      <div>
        {ticketSentStatus !== "환불완료" ? (
          <button
            onClick={() => {
              handleShow(subitem_id);
            }}
            className="flex items-center justify-center w-full"
          >
            {options?.length === 0 && (
              <>
                <img alt="" className="cursor-pointer" src={staticFiles.icons.card_calendar} />{" "}
                <span
                  className={`pl-1 sm:pl-3 ${
                    ticketSentStatus == "환불완료" ? "text-grey" : "text-[#0D9323]"
                  }`}
                >
                  예약요청
                </span>
              </>
            )}
            {options?.length === 3 && !input && (
              <span className="underline cursor-pointer font-poppins text-blue">요청완료</span>
            )}
            {input && <span className="text-start font-poppins">{rq_schedule_datetime}</span>}
          </button>
        ) : (
          <div></div>
        )}
        {showModalProp === subitem_id && !isSubmitted && (
          <Modal
            onClose={() => {
              // close actions here
            }}
            disabled={isDisabled}
          >
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                e.preventDefault();

                if (!firstDate || !secondDate || !thirdDate) {
                  toast.warn("예약 요청 시간을 3순위까지 지정해 주세요");
                  return;
                } else {
                  handleSubmit(e, subitem_id, orderNumber, ticketName, allowMultipe);
                  setTopping("");
                  setIsSubmitted(false);
                  // setShowThankModal(true);
                  setSubmitDone(true);
                  handleShow(-100);
                }
              }}
            >
              {options?.length === 3 && !input && (
                <div className="text-xl font-medium text-center  text-[#5D5D5F] ">
                  예약 요청 확인
                </div>
              )}
              {options?.length === 0 && isMultipe && !IsSubmitted && !isSubmitShow && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && isMultipe && isSubmitShow && !IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">에약 요청</div>
              )}
              {options?.length === 0 && isMultipe && IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">
                  예약 요청 확인
                </div>
              )}
              {options?.length === 0 && !isMultipe && !IsSubmitted && !isSubmitShow && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && !isMultipe && isSubmitShow && !IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">예약 요청</div>
              )}
              {options?.length === 0 && !isMultipe && IsSubmitted && (
                <div className=" text-xl font-medium text-center  text-[#5D5D5F]">
                  예약 요청 확인
                </div>
              )}

              <div className="">
                <span className="font-bold text-[#5D5D5F]">입장지 명:</span>{" "}
                <span className="font-normal text-[#5D5D5F]">{ticketName}</span>
              </div>
              {options?.length === 0 && isMultipe && (topping === "Yes" || topping === "") && (
                <div className="text-sm font-normal text-justify ">
                  주문번호 {orderNumber} 의 구매수량 모두 같은 날짜/시간으로 예약할까요?.
                </div>
              )}
              {options?.length === 0 && isMultipe && !isSubmitShow && (
                <div className="flex justify-between">
                  <div className="flex justify-between mr-3 grow font-poppins">
                    <div>
                      <input
                        className="mx-3 accent-green-600"
                        type="radio"
                        name="topping"
                        value="Yes"
                        id="Yes"
                        checked={topping === "Yes"}
                        onChange={onOptionChange}
                      />
                      <span className="cursor-pointer">네</span>
                    </div>
                    <div>
                      <input
                        className="mx-3 accent-green-600"
                        type="radio"
                        name="topping"
                        value="No"
                        id="No"
                        checked={topping === "No"}
                        onChange={onOptionChange}
                      />
                      <span className="cursor-pointer">아니오</span>
                    </div>
                  </div>
                  <div className="mx-3">
                    <img alt="" className="cursor-pointer" src={staticFiles.icons.help_circle} />
                  </div>
                </div>
              )}
              {(topping === "Yes" || topping === "No" || !isMultipe || input) && (
                <div className="text-sm">
                  <div className="mb-4">
                    <div>1순위</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="first_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 0);
                        setFirstDate(e.target.value);
                      }}
                      value={firstValueToRender}
                    />
                  </div>

                  <div className="mb-4">
                    <div>2순위</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="second_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 1);
                        setSecondDate(e.target.value);
                      }}
                      value={secondValueToRender}
                    />
                  </div>

                  <div className="mb-4">
                    <div>3순위</div>
                    <input
                      type="datetime-local"
                      className="w-full p-1 border cursor-pointer"
                      name="third_option"
                      disabled={isDisabled}
                      onChange={(e) => {
                        handleChange(e, subitem_id, 2);
                        setThirdDate(e.target.value);
                      }}
                      value={thirdValueToRender}
                    />
                  </div>
                </div>
              )}
              {!isDisabled && !IsSubmitted && isSubmitShow && (
                <div>
                  <button
                    className={"text-medium border text-white rounded px-2 py-2 w-1/2 bg-blue"}
                    type="button"
                    onClick={() => {
                      setIsSubmitted(true);
                    }}
                  >
                    요청하기
                  </button>
                  <button
                    className="w-1/2 px-2 py-2 text-white border rounded text-medium bg-gray "
                    type="reset"
                  >
                    초기화
                  </button>
                </div>
              )}
              {!isDisabled && IsSubmitted && (
                <>
                  <div>
                    <button
                      className={"text-medium border text-white rounded px-2 py-2 w-1/2 bg-blue"}
                      type="submit"
                    >
                      확인
                    </button>
                    <button
                      className="w-1/2 px-2 py-2 text-white border rounded text-medium bg-gray"
                      type="button"
                      onClick={() => {
                        handleShow(-100);
                        setTopping("");
                        setIsSubmitted(false);
                        // setShowThankModal(false);
                        setIsSubmitShow(false);
                        setFirstDate("");
                        setSecondDate("");
                        setThirdDate("");
                      }}
                    >
                      취소
                    </button>
                  </div>
                </>
              )}

              <div className="absolute text-lg font-medium top-2 right-2 ">
                <button
                  onClick={() => {
                    handleShow(-100);
                    setTopping("");
                    setIsSubmitted(false);
                    // setShowThankModal(false);
                    setIsSubmitShow(false);
                    setFirstDate("");
                    setSecondDate("");
                    setThirdDate("");
                  }}
                >
                  <img alt="" className="cursor-pointer" src={staticFiles.icons.remove} />
                </button>
              </div>
            </form>
          </Modal>
        )}
        {/* // Loading Spinner  */}
        {!isSubmitted && submitDone && <TransparentLoading />}
      </div>
    );
  } else if (ticketType === "Bar/QR code") {
    return <div>티켓받기</div>;
  } else if (ticketType === "Hard copy" || ticketType === "SIM card") {
    return <div></div>;
  } else {
    return <div>대기 중</div>;
  }
};

// Function to check if there are any items in the shopping cart
const checkItemsInShoppingCart = ({
  childInfo,
  adultInfo,
}: {
  childInfo: CartItem[];
  adultInfo: CartItem[];
}) => {
  const combinedInfo = [...childInfo, ...adultInfo];

  if (combinedInfo.length === 0) {
    return false;
  } else if (combinedInfo.length > 0 && combinedInfo[0].reservation_id != null) {
    return false;
  } else {
    return true;
  }
};

// Function to get item's data based on adult-child type
const handleAdultChild = (item: any) => {
  const adult_child_type = item?.adult_child_type;
  const child_age = item?.child_age;

  if (adult_child_type === "성인") {
    return adult_child_type;
  } else if (child_age && adult_child_type === "아동") {
    return `${adult_child_type} (Age:${child_age})`;
  } else {
    return adult_child_type;
  }
};

// Function to check if Items can be edited
const checkIfCanEdit = (item: any) => {
  let result = false;

  for (let ticket of item.tickets) {
    if (
      (ticket?.ticket_type === "Bar/QR code" &&
        !ticket?.pdf_path &&
        ticket?.refund_status !== "환불완료" &&
        ticket?.ticket_sent_status !== "환불완료") ||
      (ticket?.ticket_type === "Regular" &&
        ticket?.ticket_sent_status !== "Sent" &&
        ticket?.ticket_sent_status !== "pending" &&
        ticket?.options_schedules.length !== 3 &&
        ticket?.refund_status !== "환불완료" &&
        ticket?.refund_status !== "In Progress")
    ) {
      result = true;
      break;
    }
  }

  return result;
};

// Component to display items with subcategories
const SubcategoryView = ({ item, i }: any) => {
  const [cart, setCart] = cartState.useState();
  const [cityData, setCityData] = cityDataState.useState();
  const [cityId, setCityId] = cityIdState.useState();

  const navigate = useNavigate();

  // const canEdit = checkIfCanEdit(item);
  const canEdit = true; // Set always true to upgrade in any case

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);

  return (
    <div
      key={i}
      className="grid grid-cols-4 sm:grid-cols-7 text-xs sm:text-sm text-left sm:text-center border-2 border-dashed border-gray p-1 text-[#5D5D5F]"
    >
      <div className="py-2 text-left">{item?.product_name}</div>
      <div className="flex items-center justify-center px-6 py-2 ">{handleAdultChild(item)}</div>
      <div className="flex items-center justify-center hidden px-6 py-2 sm:block"></div>
      <div className="flex items-center justify-center px-6 py-2">{item?.quantity}</div>
      <div className="flex items-center justify-center hidden px-6 py-2 sm:block">
        {item?.refund_status === "환불완료" ? "환불완료" : ""}
      </div>
      {/* <div className="flex items-center justify-center hidden px-6 py-2 sm:block">
        {item?.item_id}
      </div> */}
      {canEdit && (
        <button
          onClick={async () => {
            if (checkItemsInShoppingCart(cart)) {
              Swal.fire({
                icon: "warning",
                //title: "Oops...",
                title: "장바구니에 담을 수 없습니다.",
                text: "현재 진행 중인 내역이 있습니다. 장바구니를 확인해 주세요.",
                //text: "You already have items in the shopping cart, Please process the existing items first in order to proceed the Booking Edit",
                confirmButtonText: "OK",
              });
            } else {
              if (item?.quantity > 1) {
                Swal.fire({
                  title: "수정하기",
                  //text: `The entire quantity ${item?.quantity} will be reflected when editing. Do you like to proceed?`,
                  html: `구매하신 수량 ${item?.quantity}개에 대해 수정이 진행됩니다. <br>계속하시겠습니까?`,
                  // icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: "네",
                  cancelButtonText: "아니오",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    let cityName = subPathWithAbbreviation.find(
                      (it: any) =>
                        it.abb == item?.subcategory_name.split(" ")[0] ||
                        it.abb == item?.subcategory_name?.substring(0, 2)
                    )?.name;
                    let cityId = cityData.cityData.find((it: any) => it.name == cityName)?.id;
                    setCityId(cityId);
                    localStorage.setItem("cityId", String(cityId));
                    localStorage.setItem("cityName", String(cityName));
                    localStorage.setItem("Edit_Item", JSON.stringify(item));

                    await new Promise((resolve) => setTimeout(resolve, 1500));

                    navigate(
                      `/package-tour/${
                        subPathFromSubCategoryName.find(
                          (group) => group.name === item?.subcategory_name
                        )?.subPath
                      }?edit=${true}&type=booking`,
                      { state: item }
                    );
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    return;
                  }
                });
              } else {
                let cityName = subPathWithAbbreviation.find(
                  (it: any) =>
                    it.abb == item?.subcategory_name.split(" ")[0] ||
                    it.abb == item?.subcategory_name?.substring(0, 2)
                )?.name;
                let cityId = cityData.cityData.find((it: any) => it.name == cityName)?.id;
                setCityId(cityId);
                localStorage.setItem("cityId", String(cityId));
                localStorage.setItem("cityName", String(cityName));
                localStorage.setItem("Edit_Item", JSON.stringify(item));

                await new Promise((resolve) => setTimeout(resolve, 1500));

                navigate(
                  `/package-tour/${
                    subPathFromSubCategoryName.find(
                      (group) => group.name === item?.subcategory_name
                    )?.subPath
                  }?edit=${true}&type=booking`,
                  { state: item }
                );
              }
            }
          }}
          className="py-2 underline text-sky-700"
        >
          수정하기
        </button>
      )}
    </div>
  );
};

// Function to extract params from the urls
const extractParams = (url: string) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const email = urlParams.get("email");
  const order_number = urlParams.get("order_number");

  return { email, order_number };
};

// Component that is used while loading with api calls
const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-16 h-16 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
  );
};

// Transparent Component that is used while loading with api calls
const TransparentLoading = () => {
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-black bg-opacity-25">
      <div className="w-16 h-16 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
    </div>
  );
};

// Component that is used for Errors
const Error = ({ error }: { error: string }) => {
   // Base url
   const baseUrl = window.location.port ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : `${window.location.protocol}//${window.location.hostname}`;

   return (
     <div className="flex items-center justify-center h-screen flex-col gap-5">
       <h1 className="text-xl">
       시스템 에러 - 1분 뒤에 닫기를 눌러주시고 다시 시도해주세요!
       </h1>
       <button className="w-[100px]  text-base font-poppins text-white bg-blue py-2 h-12 rounded text-base" onClick={() => window.location.href = baseUrl}>닫기</button>
     </div>
   );
};

// Main component to display Booking Data
export const BookingsView = () => {
  // Global state variables
  const [reservationsData, setReservationsData] = reservationsState.useState();
  const [reservationsParsedData, setReservationsParsedData] = reservationsParsedState.useState();

  // Component State variables
  const [showModal, setShowModal] = useState(-100);
  const [modalForm, setModalForm] = useState({});
  const [draftForm, setDraftForm] = useState({} as any);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDownLoadTicket, setIsDownLoadTicket] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [firstFetch, setFirstFetch] = useState(true);

  // Variable to check firstloading or not
  const [counter, setCounter] = useState(0);

  // Define navigate and search with default Hooks
  const navigate = useNavigate();
  const { search } = useLocation(); // ?email=jlfl94@gmail.com&order_number=4388608

  // Define searchQuery to extract variables from url
  let searchQuery: any;

  if (search) {
    searchQuery = extractParams("http://localhost:3000/my-page" + search);
  }

  //// Start Custom Functions
  // Function to handle show param change
  const handleShow = (show: number) => {
    setShowModal(show);
    if (show < 0) {
      setDraftForm({});
      setIsSubmitted(false);
    }
  };

  // Function to handle value change
  const handleChange = (event: any, key: number, index: number) => {
    const { name, value } = event.target;
    // console.log("event changed", { key, name, value, index });
    setDraftForm((prevForm: any) => {
      let val = prevForm[String(key)];
      if (val === undefined || !Array.isArray(val)) {
        val = new Array(3).fill(null);
      }
      val[index] = value;
      return { ...prevForm, [String(key)]: val };
    });
  };

  // Function to handle submit
  const handleSubmit = async (
    event: any,
    key: number,
    orderNumber: string,
    ticketName: string,
    allowMultipe: boolean
  ) => {
    event.preventDefault();
    // console.log("submitted", draftForm);

    const submittedForm = draftForm[String(key)];

    const isValid = submittedForm?.reduce((acc: any, item: any) => {
      return acc && typeof item === "string";
    }, true);

    if (isValid) {
      if (allowMultipe) {
        // Find items for same order number and setting schedule options at the same time
        let itemsInSameOrder = parsedData.find((it: any) => it.order_number === orderNumber);

        let subItemIdsToSchedule: any[] = [];
        itemsInSameOrder?.items?.forEach((it: any) => {
          it.tickets.forEach((singleTicket: any) => {
            if (
              singleTicket.options_schedules.length === 0 &&
              !singleTicket.rq_schedule_datetime &&
              singleTicket.ticket_title_kr === ticketName
            ) {
              subItemIdsToSchedule.push(singleTicket.subitem_id);
            }
          });
        });

        try {
          await Promise.all(
            subItemIdsToSchedule.map((it) => {
              return updateScheduleOptions(it, submittedForm);
            })
          );

          setIsSubmitted(true);

          subItemIdsToSchedule.forEach((it: any) => {
            setModalForm((prevForm: any) => {
              return { ...prevForm, [String(it)]: submittedForm };
            });
          });
        } catch (e) {
          setIsSubmitted(true);
          subItemIdsToSchedule.forEach((it: any) => {
            setModalForm((prevForm: any) => {
              return { ...prevForm, [String(it)]: submittedForm };
            });
          });
        }
      } else {
        try {
          const res = await updateScheduleOptions(key, submittedForm);
          setIsSubmitted(true);

          setModalForm((prevForm: any) => {
            return { ...prevForm, [String(key)]: submittedForm };
          });
        } catch (e) {
          setIsSubmitted(true);

          setModalForm((prevForm: any) => {
            return { ...prevForm, [String(key)]: submittedForm };
          });
        }
      }
    }
  };

  // Function to check auth token expiration
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };
  // End Custom Functions

  // Coustom Hook with API calls
  const { isLoading, isError, data } = useGetReservations(
    searchQuery,
    isSubmitted,
    firstFetch,
    isDownLoadTicket
  );
  // const {  isLoading, data, isError } = useGetReservations(searchQuery, isSubmitted);

  // Memoized Variables
  const parsedPriceOptions = useMemo(() => {
    const priceOptionsMap = new Map();
    if (!isLoading && !isError && data) {
      data?.priceOptions?.forEach((option: any) => {
        priceOptionsMap.set(String(option.id), option.product_type);
      });
    }
    return priceOptionsMap;
  }, [data, isLoading, isError]);

  const parsedScheduleOptions = useMemo(() => {
    let options = {} as any;
    if (!isLoading && !isError && data) {
      data?.scheduleOptions?.forEach((option: any) => {
        const optionsData = option;
        if (optionsData && optionsData.length > 0) {
          const optionsInOrder: string[] = optionsData
            .sort((a: any, b: any) => a?.order - b?.order)
            .map((option: any) => convertToISO8601(option.datetime));
          options[String(optionsData[0]?.reservation_sub_item_id)] = optionsInOrder;
        }
      });
    }
    return options;
  }, [data, isLoading, isError]);

  const parsedTickets = useMemo(() => {
    const tickets = new Map();
    if (!isLoading && !isError && data) {
      data?.tickets?.forEach((ticket: any) => {
        const ticket_id = ticket?.id;
        if (ticket_id) {
          tickets.set(String(ticket_id), ticket);
        }
      });
    }
    return tickets;
  }, [data, isLoading, isError]);

  const parsedSubcategories = useMemo(() => {
    const subcategories = new Map();
    if (!isLoading && !isError && data) {
      data?.categories?.forEach((category: any) => {
        const id = category?.id;
        if (id && !subcategories.has(id)) {
          subcategories.set(String(id), category);
        }
      });
    }
    return subcategories;
  }, [data, isLoading, isError]);

  const parsedData: any = useMemo(() => {
    return data?.reservations
      ?.slice()
      .sort((a: any, b: any) => {
        const dateComparison =
          new Date(b?.order_date).getTime() - new Date(a?.order_date).getTime();
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return b?.reservation_items[0].id - a?.reservation_items[0].id;
      })
      ?.map((reservation: any) => {
        return {
          order_number: reservation?.order_number,
          order_date: reservation?.order_date,
          items: reservation?.reservation_items
            ?.slice()
            .sort((a: any, b: any) => b?.id - a?.id)
            ?.map((item: any) => {
              return {
                reservation_id: reservation?.id,
                price_list_id: item?.price_list_id,
                hasSubcategory: !!item?.subcategory_id,
                product_name: parsedPriceOptions?.get(String(item?.price_list_id)),
                subcategory_name:
                  // parsedPriceOptions?.get(item?.price_list_id) ||
                  parsedSubcategories?.get(String(item?.subcategory_id))?.name,
                subcategory_id: item?.subcategory_id,
                item_id: item?.id,
                adult_child_type: item?.adult_child_type,
                child_age: item?.child_age,
                quantity: item?.quantity,
                price: item?.price,
                addition: item?.addition,
                total: item?.total,
                refund_status: item?.refund_status,
                tickets: item?.reservation_sub_items
                  ?.slice()
                  .sort((a: any, b: any) => b?.id - a?.id)
                  ?.map((subitem: any) => {
                    return {
                      subitem_id: subitem?.id,
                      pdf_path: subitem?.pdf_path,
                      rq_schedule_datetime: subitem?.rq_schedule_datetime,
                      ticket_sent_status: subitem?.ticket_sent_status,
                      options_schedules: subitem?.options_schedules,
                      refund_status: subitem?.refund_status,
                      ticket_id: subitem?.ticket_id,
                      seating_info: subitem?.seating_info,
                      price: subitem?.price,
                      addition: subitem?.addition,
                      quantity: subitem?.quantity,
                      total: subitem?.total,
                      ticket_type: parsedTickets?.get(String(subitem?.ticket_id))?.ticket_type,
                      ticket_title_en: parsedTickets?.get(String(subitem?.ticket_id))?.title_en,
                      ticket_title_kr: parsedTickets?.get(String(subitem?.ticket_id))?.title_kr,
                      ticket_city_id: parsedTickets?.get(String(subitem?.ticket_id))?.city_id,
                      additional_price_type: parsedTickets?.get(String(subitem?.ticket_id))
                        ?.additional_price_type,
                      additional_price_image: parsedTickets?.get(String(subitem?.ticket_id))
                        ?.additional_price_image,
                      additional_price_amount: parsedTickets?.get(String(subitem?.ticket_id))
                        ?.additional_price_amount,
                    };
                  }),
              };
            }),
        };
      });
  }, [data, parsedSubcategories, parsedTickets, parsedPriceOptions]);

  // Start useEffect Hooks
  useEffect(() => {
    if (data) {
      if (data.reservations) {
        // Create a new array or object with the updated data
        const updatedReservations = { reservations: [...data.reservations] };
        const updatedParsedReservations = {
          reservations: [
            ...data?.reservations
              ?.slice()
              .sort((a: any, b: any) => {
                const dateComparison =
                  new Date(b?.order_date).getTime() - new Date(a?.order_date).getTime();
                if (dateComparison !== 0) {
                  return dateComparison;
                }
                return b?.reservation_items[0].id - a?.reservation_items[0].id;
              })
              ?.map((reservation: any) => {
                return {
                  order_number: reservation?.order_number,
                  order_date: reservation?.order_date,
                  reservation_id: reservation.id,
                  items: reservation?.reservation_items
                    ?.slice()
                    .sort((a: any, b: any) => b?.id - a?.id)
                    ?.map((item: any) => {
                      return {
                        item_id: item?.id,
                        reservation_id: reservation?.id,
                        price_list_id: item?.price_list_id,
                        hasSubcategory: !!item?.subcategory_id,
                        product_name: parsedPriceOptions?.get(String(item?.price_list_id)),
                        subcategory_name:
                          // parsedPriceOptions?.get(item?.price_list_id) ||
                          parsedSubcategories?.get(String(item?.subcategory_id))?.name,
                        subcategory_id: item?.subcategory_id,
                        adult_child_type: item?.adult_child_type,
                        child_age: item?.child_age,
                        quantity: item?.quantity,
                        price: item?.price,
                        addition: item?.addition,
                        total: item?.total,
                        refund_status: item?.refund_status,
                        tickets: item?.reservation_sub_items
                          ?.slice()
                          .sort((a: any, b: any) => b?.id - a?.id)
                          ?.map((subitem: any) => {
                            return {
                              subitem_id: subitem?.id,
                              rq_schedule_datetime: subitem?.rq_schedule_datetime,
                              ticket_sent_status: subitem?.ticket_sent_status,
                              refund_status: subitem?.refund_status,
                              ticket_id: subitem?.ticket_id,
                              price: subitem?.price,
                              addition: subitem?.addition,
                              quantity: subitem?.quantity,
                              total: subitem?.total,
                              ticket_type: parsedTickets?.get(String(subitem?.ticket_id))
                                ?.ticket_type,
                              ticket_title_en: parsedTickets?.get(String(subitem?.ticket_id))
                                ?.title_en,
                              ticket_title_kr: parsedTickets?.get(String(subitem?.ticket_id))
                                ?.title_kr,
                              additional_price_type: parsedTickets?.get(String(subitem?.ticket_id))
                                ?.additional_price_type,
                              additional_price_image: parsedTickets?.get(String(subitem?.ticket_id))
                                ?.additional_price_image,
                              additional_price_amount: parsedTickets?.get(
                                String(subitem?.ticket_id)
                              )?.additional_price_amount,
                            };
                          }),
                      };
                    }),
                };
              }),
          ],
        };

        // Assign the updated value to the reservations state
        setReservationsData(updatedReservations);
        localStorage.setItem("reservationData", JSON.stringify(updatedReservations));
        setReservationsParsedData(updatedParsedReservations);
        localStorage.setItem("reservationParsedData", JSON.stringify(updatedParsedReservations));
      }
    }
  }, [data]);

  useEffect(() => {
    // setFirstLoading(false);
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    setCounter((prev) => prev + 1);

    // Check this counter to show loading spinner only for the first time loading
    if (counter == 1) {
      setFirstLoading(false);
    }

    setModalForm((prevState) => ({ ...prevState, ...parsedScheduleOptions }));
  }, [parsedScheduleOptions]);
  // End useEffect Hooks

  // Loading Screen
  if (isLoading && firstLoading) {
    return (
      <div className="flex flex-col w-full mt-16 bg-white gap-x-8 md:flex-row md:relative">
        <div className="flex flex-col w-full">
          <Loading />
        </div>
      </div>
    );
  }

  // Error Display when something is wrong with api call
  if (isError) {
    return (
      <div className="flex flex-col w-full mt-16 bg-white gap-x-8 md:flex-row md:relative">
        <div className="flex flex-col w-full">
          <Error error={""} />
        </div>
      </div>
    );
  }

  return (
    <>
      {parsedData && (
        <div className="flex flex-col w-full p-8 bg-white">
          {!localStorage.getItem("orderLoginNumber") && (
            <span className="mb-5 text-xl font-bold font-poppins">마이페이지</span>
          )}

          {localStorage.getItem("orderLoginNumber") && (
            <div className="flex justify-between mb-10 ">
              <span className="mb-5 text-xl font-bold font-poppins text-[#5D5D5F]">마이페이지</span>
              <div>
                <div className="flex justify-end text-[#5D5D5F]">
                  주문날짜: {parsedData[0]?.order_date}
                </div>
                <div className="flex justify-end text-[#5D5D5F]">
                  주문번호: {parsedData[0]?.order_number}
                </div>
              </div>
            </div>
          )}
          {isSubmitted && (
            <Modal
              onClose={() => {
                // close actions here
              }}
            >
              <div
                className="font-poppins"
                // onSubmit={(e) => handleSubmit(e, subitem_id)}
              >
                <div className="mb-8">
                  <img
                    alt=""
                    className="cursor-pointer"
                    src={staticFiles.icons.schedule_optionconfirm}
                  />
                </div>
                <div className="mb-16 text-xl font-medium text-center text-[#5D5D5F]">
                  티켓 준비중
                </div>
                <div className="mb-24 font-normal text-[#5D5D5F]">
                  티켓이 준비되면 이메일을 통해 티켓을 받게 됩니다. 문의사항이 있으시면
                  service@tamice.com으로 연락주세요.
                  {/* Ticket Name: <span className="font-bold">{ticketName}</span> */}
                </div>
                {/* <div className="mb-6 text-sm">감사합니다.</div> */}
                <button
                  className="w-full px-2 py-2 text-white border rounded text-medium bg-blue"
                  onClick={() => {
                    // setShowThankModal(false);
                    // setSubmitDone(false);
                    // setIsSubmitShow(false);
                    // handleShow(-100);
                    setIsSubmitted(false);
                    setFirstFetch(false);
                  }}
                >
                  닫기
                </button>
                <div className="absolute text-lg font-medium top-2 right-2 ">
                  <button
                    onClick={() => {
                      // setShowThankModal(false);
                      // setSubmitDone(false);
                      // setIsSubmitShow(false);
                      // handleShow(-100);
                      setIsSubmitted(false);
                      setFirstFetch(false);
                    }}
                  >
                    <img alt="" className="cursor-pointer" src={staticFiles.icons.remove} />
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {parsedData?.map((reservation: any, i: number) => {
            return (
              <div key={i} className="w-full mb-4">
                {i > 0 && (
                  <hr className="my-4 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                )}
                {!localStorage.getItem("orderLoginNumber") && (
                  <div className="flex justify-end text-[#5D5D5F] text-xs sm:text-sm">
                    주문번호: {reservation.order_number}
                  </div>
                )}
                {!localStorage.getItem("orderLoginNumber") && (
                  <div className="flex justify-end pb-5 text-[#5D5D5F] text-xs sm:text-sm">
                    주문날짜: {reservation.order_date}
                  </div>
                )}

                <div className="grid grid-cols-4 sm:grid-cols-7 text-xs sm:text-sm text-[#5D5D5F] text-center">
                  <div className="px-2 py-1">상품</div>
                  <div className="px-2 py-1">성인/아동</div>
                  <div className="hidden px-2 py-1 sm:block">예약날짜/시간</div>
                  <div className="px-2 py-1">수량</div>
                  <div className="hidden px-2 py-1 sm:block">마이티켓</div>
                  {/* <div className="hidden px-2 py-1 sm:block">Item ID</div> */}
                  <div className="px-2 py-1"></div>
                </div>
                <hr className="my-3 h-0.5 border-t-0 bg-black opacity-40 dark:opacity-40" />
                {reservation?.items
                  ?.sort((a: any, b: any) => a.item_id - b.item_id)
                  .map((item: any, i: number) => {
                    if (item?.hasSubcategory) {
                      return (
                        <>
                          <SubcategoryView item={item} i={i} />
                          <TicketViewMemo
                            isLoading={isLoading}
                            orderNumber={reservation?.order_number}
                            item={item}
                            reservation={reservation}
                            showModal={showModal}
                            handleShow={handleShow}
                            modalForm={modalForm}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setIsDownLoadTicket={setIsDownLoadTicket}
                            isSubmitted={isSubmitted}
                            hasSubcategory={true}
                          />
                        </>
                      );
                    } else {
                      return (
                        <TicketViewMemo
                          orderNumber={reservation?.order_number}
                          item={item}
                          reservation={reservation}
                          showModal={showModal}
                          handleShow={handleShow}
                          modalForm={modalForm}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          setIsDownLoadTicket={setIsDownLoadTicket}
                          isSubmitted={isSubmitted}
                          hasSubcategory={false}
                        />
                      );
                    }
                  })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
