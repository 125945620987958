import { staticFiles } from "..";
import { MainButton } from "./Buttons";
// import { PUBLIC_URL } from "..";
// import { CardTypes, LocationCard, LocationCardProps } from "./LocationCard";
// import { cityIdState, cityDataState } from "../../App";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SpaceY } from "./Utils";
import Modal from "@material-ui/core/Modal";
import { Checkbox, Button } from "@material-ui/core";
import { useLocation } from "react-router";
import { useMemo } from "react";
import { cityIdState, cityDataState } from "../../App";
// Function to compare two date objects
function isSameDay(date1: any, date2: any) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const LandingCover = () => {
  // Define Global State Variables
  // const [cityId, setCityId] = cityIdState.useState();
  // const [cityData, setCityData] = cityDataState.useState();

  // Flag varialbe to check if the PopUp modal was opened today
  let openPopUpModal = true;
  const [cityId, setCityId] = cityIdState.useState();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  const handleButtonClick = () => {
    if (cityId === 1) {
      window.location.href = "/package-tour/ba-pass";
    } else if (cityId === 36) {
      window.location.href = "/package-tour/ba-pass";
    } else {
      window.location.href = "/webpage/101";
    }
  };

  const handleButtonClickMain = () => {
    window.location.href = "/webpage/101";
  };

  useEffect(() => {
    setCurrentPath(location?.pathname);
  }, [location]);
  if (localStorage.getItem("openedPopUpModal")) {
    if (
      localStorage.getItem("openedPopUpModal") == "true" &&
      isSameDay(new Date(localStorage.getItem("openedPopUpModalTime") ?? ""), new Date())
    ) {
      openPopUpModal = false;
    }
  }

  // Define state variable to decide to open the modal or not
  const [modalIsOpen, setModalIsOpen] = useState(openPopUpModal);

  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  // Define useEffect Hook
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Modal open={modalIsOpen} className="flex justify-center items-center">
        <div className="w-[80vw] md:w-[400px] bg-white p-5 rounded-[30px]">
          <div className="flex justify-center items-center">
            <img
              src={staticFiles.images.popup_image}
              className="w-full object-cover sm:max-w-[25vw]"
            />
          </div>
          <div className="flex justify-end items-center">
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  // Save checked result in the LocalStorage
                  localStorage.setItem("openedPopUpModal", "true");
                  localStorage.setItem("openedPopUpModalTime", new Date().toDateString());
                } else {
                  // Save checked result in the LocalStorage
                  localStorage.setItem("openedPopUpModal", "false");
                  localStorage.setItem("openedPopUpModalTime", new Date().toDateString());
                }
              }}
              color="primary"
              size={window.innerWidth < 768 ? "small" : "medium"}
            />
            <p className="text-xs sm:text-sm">오늘 하루동안 이 창을 열지 않음</p>
          </div>
          <div className="flex justify-end items-center">
            <Button
              variant="contained"
              color="primary"
              size={window.innerWidth < 768 ? "small" : "medium"}
              onClick={() => setModalIsOpen(false)}
            >
              닫기
            </Button>
          </div>
        </div>
      </Modal>
      {/* New York Mobile */}
      <div className="flex flex-col lg:hidden justify-between px-[5vw] max-w-[1300px]">
        <SpaceY />
        <div className="flex flex-col">
          <div className="flex flex-col items-center w-full">
            <span className="font-poppins font-medium text-[#DF6951]">
              빅애플패스로 함께하는 쉽고 편리한 여행
            </span>
            <span className="text-2xl font-bold leading-tight font-volkhov text-dark lg:text-2xl">
              설레는 여행의 시작과 끝 <br />
              with.타미스
            </span>
          </div>
        </div>
        <SpaceY /> <SpaceY />
        <div className="flex justify-center">
          <img src={staticFiles.images.landing_page} />
        </div>
        <SpaceY /> <SpaceY />
        <div className="flex flex-col">
          <div className="font-poppins text-dargGray">
            미국 서부 샌프란시스코부터 세계 최고의 도시 뉴욕까지! 타미스와 함께라면 꿈꾸어왔던
            미국여행도 자유롭게, 쉽게 즐기실 수 있습니다. 고객님의 즐거운 여행의 순간을 함께
            만들어나가는 타미스는 자유여행에 딱 맞는 정책과 투명한 운영으로 고객님의 여행을 편안하게
            만들어 드립니다.
          </div>
          <SpaceY />
          <div className="flex">
            <MainButton
              containerClassName="w-[200px] rounded"
              text="자세히 보기"
              onClick={handleButtonClickMain}
            />
          </div>
        </div>
      </div>
      {/* New York*/}
      <div className="max-h-[420px] hidden lg:flex justify-between px-[6.5rem] max-w-[1300px] my-4 mt-[110px]">
        <div className="flex flex-col justify-between w-7/12">
          <div className="flex flex-col w-full">
            <span className="font-poppins font-medium text-[#DF6951]">
              빅애플패스로 함께하는 쉽고 편리한 여행
            </span>
            <span className="text-2xl font-bold leading-tight font-volkhov text-dark lg:text-2xl">
              설레는 여행의 시작과 끝 <br />
              with.타미스
            </span>
          </div>

          <div className="mr-20 font-poppins text-dargGray">
            미국 서부 샌프란시스코부터 세계 최고의 도시 뉴욕까지! 타미스와 함께라면 꿈꾸어왔던
            미국여행도 자유롭게, 쉽게 즐기실 수 있습니다. 고객님의 즐거운 여행의 순간을 함께
            만들어나가는 타미스는 자유여행에 딱 맞는 정책과 투명한 운영으로 고객님의 여행을 편안하게
            만들어 드립니다.
          </div>
          <div className="flex-col hidden md:flex">
            <SpaceY />
            <div className="flex">
              <MainButton
                containerClassName="w-[200px] rounded"
                text="자세히 보기"
                onClick={handleButtonClickMain}
              />
            </div>
          </div>
        </div>

        <div className="flex items-end justify-end w-7/12">
          <img className="max-h-[420px] min-h-[200px]" src={staticFiles.images.landing_page} />
        </div>
      </div>
    </>
  );
};
