import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createState } from "state-pool";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import TimerProvider from "./shared/context/timerContext";
import { API, checkMenuItemType } from "./shared";
import { NavBarElement } from "./shared/components/NavBar";
import { router } from "./shared/routes";
import { useCacheCart, useCityState, useMusicState } from "./shared/hooks";
import { MedalEnum } from "./modules/cart/components/CardInfo";

// Import css files
import "./styles.css";
import "react-phone-number-input/style.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

export const authState = createState(localStorage.getItem("AUTH_TOKEN"));
export const musicState = createState<{
  music_id: string;
}>({
  music_id: "",
});

export type CartItem = {
  cartId: string;
  tour_date?: string;
  reservation_id: number | null;
  item_id: number | null;
  adult_child_type: "성인" | "아동";
  name: string;
  price: number;
  priceOptionTitle?: string;
  priceOptionId?: string;
  quantity: number;
  addition: number;
  subtotal: number;
  additional_price_type?: string;
  additional_price_image?: string;
  subCategoryName?: string;
  cityId?: string;
  categoryId?: string;
  subCategoryId?: string;
  subCategoryTitle?: string;
  subCategoryPrice?: number;
  subPath?: string;
  ticket_type?: string;
  ticket_sent_status?: string | null;
  refund_status?: string | null;
  ticket_id?: string;
  location?: string;
  musical_order?: string;
  music_id?: string;
  includes?: {
    medal: MedalEnum;
    name: string;
    addition?: number;
    scheduledDate?: string;
  }[];
};

export const cityState = createState<{
  city_id: string;
  cityName: string;
}>({
  city_id: "1",
  cityName: "뉴욕",
});
const pageNavBar: NavBarElement[] = [
  {
    name: "뉴욕 홈",
    path: "/main",
    menuType: "path"
  },
  {
    name: "뉴욕패스",
    path: "/package-tour",
    menuType: "path",
    dropdownElements: [
      { name: "뉴욕빅애플패스", subPath: "ba-pass", id: 97, category_id: 1 },
      { name: "뉴욕시티패스", subPath: "city-pass", id: 98, category_id: 1 },
      {
        name: "뉴욕익스플로러패스",
        subPath: "explore-pass",
        id: 99,
        category_id: 1,
      },
    ],
  },
  {
    name: "뉴욕입장권",
    path: "/city-attractions",
    menuType: "path",
    dropdownElements: [
      { name: "전망대", subPath: "observations", id: 4, category_id: 2 },
      {
        name: "미술관/박물관",
        subPath: "museum-gallery",
        id: 101,
        category_id: 2,
      },
      { name: "크루즈", subPath: "rides-cruises", id: 102, category_id: 2 },
      { name: "액티비티", subPath: "activities", id: 103, category_id: 2 },
      { name: "버스투어", subPath: "bus", id: 227, category_id: 2 },
      { name: "공항셔틀", subPath: "airport", id: 228, category_id: 2 },
    ],
  },
  {
    name: "가이드투어",
    path: "/guide-tour",
    menuType: "path",
    dropdownElements: [
      {
        name: "뉴욕 데이투어",
        subPath: "manhattan-day",
        id: 2,
        category_id: 4,
      },
      {
        name: "뉴욕 야경투어",
        subPath: "manhattan-night",
        id: 104,
        category_id: 4,
      },
      { name: "도슨트 투어", subPath: "doson-tour", id: 105, category_id: 4 },
      { name: "UN 투어", subPath: "un-tour", id: 106, category_id: 4 },
      { name: "근교 투어", subPath: "neighbour-tour", id: 229, category_id: 4 },
    ],
  },
  {
    name: "브로드웨이 뮤지컬",
    path: "/musicals_view",
    menuType: "path"
    // dropdownElements: [{ name: "Musicals & Shows", subPath: "", id: 189, category_id: 57 }],
  },
  {
    name: "미국유심",
    path: "/sim-card",
    menuType: "path"
  },
  {
    name: "이벤트/정보",
    path: "/trip-info",
    menuType: "path"
  },
  {
    name: "타미스?!",
    path: "/about",
    menuType: "path"
  },

  // {
  //   name: "나의 예약상품 조회",
  //   path: "/user/non-member-order-lookup",
  // },
  // {
  //   name: "나의 예약상품 조회",
  //   path: "/user/non-member-order-lookup",
  // },
  // {
  //   name: "Contact Us",
  //   path: "/contact",
  // },
];

export const menuState = createState<
  {
    path: string;
    name: string;
    menuType: string;
    templateId?: string | null;
    externalUrl?: string | null;
    dropdownElements?: {
      name: string;
      subPath: string;
      id?: number;
      category_id?: number;
      templateId?: string | null;
      externalUrl?: string | null;
    }[];
  }[]
>(pageNavBar);

export const cartState = createState<{
  childInfo: CartItem[];
  adultInfo: CartItem[];
}>({
  childInfo: [
    // {
    //   name: "Big Apple 2",
    //   price: 100,
    //   quantity: 1,
    //   addition: 27,
    //   subtotal: 137,
    //   includes: [
    //     {
    //       medal: MedalEnum.GOLD,
    //       name: "Summit Observation Deck",
    //       addition: 18,
    //     },
    //     {
    //       medal: MedalEnum.SILVER,
    //       name: "Moma Museum Doson Tour",
    //       scheduledDate: "01/25/2023 (10:30 AM)",
    //       addition: 9,
    //     },
    //   ],
    // },
  ],
  adultInfo: [],
});

export const reservationsState = createState<{
  reservations: unknown[];
}>({
  reservations: [],
});

export const reservationsParsedState = createState<{
  reservations: unknown[];
}>({
  reservations: [],
});

export const cityDataState = createState<{
  cityData: any[];
}>({
  cityData: [],
});

export const orderLookupState = createState<{
  orderLoginNumber: string;
  orderLoginEmail: string;
  phone: string;
  customer_name_kr: string;
  customer_name_en: string;
}>({
  orderLoginNumber: "",
  orderLoginEmail: "",
  phone: "",
  customer_name_kr: "",
  customer_name_en: "",
});

export const pageNumState = createState<number>(5);

export const cityIdState = createState<number>(
  Number(localStorage.getItem("cityId")) || 1
);

function App() {
  // Define Global State variables
  const [cityId] = cityIdState.useState();
  const [menu, setMenu] = menuState.useState();
  const [errors, setErrors] = useState("");

  // Set the default city data
  // localStorage.setItem("cityId", "1");
  // localStorage.setItem("cityName", "New York");

  // Define useEffect Hook
  useEffect(() => {
    fetch(`${API}/categories?city_id=${Number(cityId)}`)
      .then((res) => res.json())
      .then((data) => {

        let menuData: any = [];

        // Get city name by selected cityId
        let selectedCityName = localStorage.getItem("cityName") || "뉴욕";
        
        // Add cityNmae Home to menuData as the first item
        menuData.push(
          {
            name: `${selectedCityName} 홈`,
            path: "/main",
            menuType: "path"
          },
        );

        data.forEach((obj: any, index: number) => {
          let menuType = checkMenuItemType(obj);

          // Adde dropdownElements if there are subcategories
          if (obj.subcategories && obj.subcategories.length !== 0) {
            let dropdownElements: any = []
            obj.subcategories.forEach((item: any, idx: number) => {
              dropdownElements.push(
                {
                  name: item.name,
                  id: item.id,
                  category_id: obj.id,
                  subPath: item.subpath, // it should be item.subPath
                  templateId: item.template_id,
                  externalUrl: item.external_url
                }
              );
            });

            menuData.push(
              {
                name: obj.name,
                path: "/" + obj.path, // it should be obj.path
                dropdownElements,
                menuType,
                templateId: obj.template_id,
                externalUrl: obj.external_url
              }
            );
          } else { // if there are no subcategories
            menuData.push(
              {
                name: obj.name,
                path: "/" + obj.path, // it should be obj.path
                menuType,
                templateId: obj.template_id,
                externalUrl: obj.external_url
              }
            );
          }
        });
        
        // Add common items at the end
        menuData.push(
          {
            name: "미국유심",
            path: "/sim-card",
            menuType: "path"
          },
          {
            name: "이벤트/정보",
            path: "/trip-info",
            menuType: "path"
          },
          {
            name: "타미스?!",
            path: "/about",
            menuType: "path"
          },
        );
        const uniqueMenuData = Array.from(
          new Set(menuData.map((item: any) => item.name))
        ).map((name) => {
          return menuData.find((item: any) => item.name === name);
        });

        setMenu(uniqueMenuData);
        localStorage.setItem("cityId", String(cityId));
      })
      .catch((error) => {
        console.log("Error in App.jsx for fetching:", error);
        setErrors(error.message);
      });
  }, []);

  useCacheCart();
  useCityState();
  useMusicState();

  if (errors) {
    // Base url
   const baseUrl = window.location.port ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : `${window.location.protocol}//${window.location.hostname}`;

   return (
     <div className="flex items-center justify-center h-screen flex-col gap-5">
       <h1 className="text-xl">
       시스템 에러 - 1분 뒤에 닫기를 눌러주시고 다시 시도해주세요!
       </h1>
       <button className="w-[100px]  text-base font-poppins text-white bg-blue py-2 h-12 rounded text-base" onClick={() => window.location.href = baseUrl}>닫기</button>
     </div>
   );
  }

  return (
    <TimerProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </TimerProvider>
  );
}

export default App;
