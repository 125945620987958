import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetMusicals } from "../../shared/hooks";


export const MusicalView = () => {
  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  // Define Component State Variables
  const [page, setPage] = useState(5);
  const [isShowing, setIsShowing] = useState(true);

  // Fetch tickets from useGetMusicals with API call
  const { tickets } = useGetMusicals({
    category: 57,
    guideFilter: true,
  });

  // Define fakeData
  const fakeData = {
    image:
      "https://testing.thernloven.com/tickets-api-new/public/storage/images/cYCzQK9Br9HCLGDEAYVdSF9Ag9D2rdERUkwmedkv.png",
    en: "Lion King",
    kr: "KR_Lion King",
    description: "뉴욕의 명소 브로드웨어. - 연일 매진행렬을 이어오는 가장 인기 있는 뮤지컬",
  };

  // Function to handle click event
  const handleClick = () => {
    // Use the length of the tickets to display all
    setPage(tickets?.length || 0); // Set the page number to the total number of tickets
    setIsShowing(false); // Hide the '전체보기' button
  };

  // Define useEffect Hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  // This effect seems unnecessary as it resets the page to 3 on every render
  // Consider removing it or adding dependencies if it's meant to run under specific conditions
  useEffect(() => {
    setPage(5);
  }, []);


  return (
    <div className="flex flex-col justify-center w-full gap-x-3">
      <div className="flex flex-col gap-y-8 w-full max-w-[1300px]">
        {tickets?.slice(0, page).map((data) => (
          <div
            className="flex flex-col items-center justify-center justify-between w-full p-8 bg-white md:flex-row rounded-b-3xl"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/musicals_view/${data.id}`)}
          >
            <div className="relative flex items-center justify-center w-full ">
              <img className="object-cover h-full max-w-[200px]" src={data.image} />
            </div>
            <div className="flex flex-col items-center justify-center w-full my-8 font-poppins gap-y-3">
              <span
                className="font-bold text-dark w-full text-[#5D5D5F]  flex justify-center"
                style={{ fontSize: "30px" }}
              >
                {data.name}
              </span>
              <span
                className="font-bold text-dark w-full text-[#5D5D5F] flex justify-center "
                style={{ fontSize: "30px" }}
              >
                {data.kr_name}
              </span>
              <div className="flex items-center justify-center w-full">
                <span className="text-sm text-[#5D5D5F] ">{data?.description}</span>
              </div>
            </div>
          </div>
        ))}
        {isShowing && tickets && tickets.length > page ? (
          <div
            className="flex justify-center text-blue hover:cursor-pointer hover:underline"
            onClick={handleClick}
          >
            전체보기
          </div>
        ) : null}
      </div>

    </div>
  );
};
