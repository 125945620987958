import { ReactNode } from "react";
import { Cover } from "../../shared/components/Cover";
// import { CoverWithoutText } from "../../shared/components/CoverNoText";
import { InfoCover } from "../../shared/components/InfoCover";
import { LandingCover } from "../../shared/components/LandingCover";
import { ShowCover } from "../../shared/components/ShowCover";
import { Footer } from "./components/Footer";
import { Top } from "./components/Top";

export enum CoverTypes {
  NORMAL,
  NORMAL_WITHOUT_TEXT,
  INFO,
  SHOWS,
  NONE,
  LANDING
}

export const PageLayout: React.FC<{
  children: ReactNode;
  cover?: CoverTypes;
  backgroundColor?: string;
}> = ({ children, cover, backgroundColor = "bg-transparent" }) => (
  <div
    className={`flex flex-col items-center min-h-screen bg-[#F7F8FA] bg-no-repeat`}
  >
    <Top />
    {cover === CoverTypes.NORMAL && <Cover />}
    {cover === CoverTypes.NORMAL_WITHOUT_TEXT && <Cover />}
    {cover === CoverTypes.INFO && <InfoCover />}
    {cover === CoverTypes.LANDING && <LandingCover />}
    {cover === CoverTypes.SHOWS && <Cover />}
    <div className={` lg:max-w-[1300px] max-w-[800px] w-full px-4 flex justify-center`}>
      {children}
    </div>
    <Footer />
  </div>
);
